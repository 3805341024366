import React, { useEffect, useState, useCallback } from 'react';
import { getReviewsWithReportsAboveLimit } from '../../../services/api';
import { useAuth0 } from '@auth0/auth0-react';
import './ReviewsWithReportsMetric.css';

const ReviewsWithReportsMetric = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [limit, setLimit] = useState(3); // Estado para manejar el límite

  const fetchMetrics = useCallback(async (currentLimit) => {
    try {
      const token = await getAccessTokenSilently();
      const result = await getReviewsWithReportsAboveLimit(token, currentLimit);
      setData(result);
    } catch (err) {
      console.error('Error obteniendo reseñas con reportes por encima del límite:', err);
      setError('No se pudieron obtener los datos de reseñas con reportes.');
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchMetrics(limit);
  }, [fetchMetrics, limit]);

  const handleOpenUser = (id) => {
    window.open(`/profile/${id}`, '_blank'); // Abrir el perfil del usuario en una nueva pestaña
  };

  const handleOpenEvent = (id) => {
    window.open(`/event/${id}`, '_blank'); // Abrir el evento en una nueva pestaña
  };

  return (
    <div className="reviews-reports controlmetric-card">
      <header className="reviews-reports-header">
        <h2>Reseñas con {limit + 1} o más Reportes</h2>
        <div className="limit-selector">
          <label htmlFor="limit">Límite de reportes:</label>
          <input
            id="limit"
            type="number"
            min="1"
            value={limit + 1} // Mostrar el valor incrementado en 1
            onChange={(e) => setLimit(Number(e.target.value) - 1)} // Almacenar el valor decrementado en 1
            className="limit-input"
          />
        </div>
      </header>

      {error ? (
        <p className="error-message">{error}</p>
      ) : data && data.reviews ? (
        data.reviews.length > 0 ? (
          <section className="table-section">
            <table className="reports-table">
              <thead>
                <tr>
                  <th>ID Reseña</th>
                  <th>Contenido</th>
                  <th>ID Evento</th>
                  <th>Usuario</th>
                  <th>Reportes</th>
                </tr>
              </thead>
              <tbody>
                {data.reviews.map((review) => (
                  <tr key={review.id_review}>
                    <td>{review.id_review}</td>
                    <td>{review.content}</td>
                    <td>
                      <span
                        onClick={() => handleOpenEvent(review.id_event)}
                        className="clickable-link"
                      >
                        {review.id_event}
                      </span>
                    </td>
                    <td>
                      <span
                        onClick={() => handleOpenUser(review.id_user)}
                        className="clickable-link"
                      >
                        {review.user}
                      </span>
                    </td>
                    <td>{review.number_reports}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        ) : (
          <p>No hay reseñas que cumplan con el límite establecido.</p>
        )
      ) : (
        <p>Cargando datos...</p>
      )}
    </div>
  );
};

export default ReviewsWithReportsMetric;

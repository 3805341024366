import React, { useEffect, useState, useCallback } from 'react';
import { getTopCategoriesMetrics } from '../../../services/api';
import { useAuth0 } from '@auth0/auth0-react';
import './TopCategoriesMetric.css';

const TopCategoriesMetric = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [limit, setLimit] = useState(3);

  const fetchMetrics = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const result = await getTopCategoriesMetrics(token, limit);
      setData(result);
    } catch (err) {
      console.error('Error obteniendo métricas de categorías principales:', err);
      setError('No se pudieron obtener las métricas de categorías principales.');
    }
  }, [getAccessTokenSilently, limit]);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setData(null); // Limpia los datos para nueva búsqueda
    setError(null); // Limpia errores anteriores
    fetchMetrics();
  };

  if (error) return <div className="metric-card">{error}</div>;

  return (
    <div className="metric-card">
      <h2>Top Categorías por Eventos</h2>
      <form onSubmit={handleSubmit} className="metric-form">
        <label>
          Límite:
          <input
            type="number"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
            min="1"
            className="input-field"
          />
        </label>
        <button type="submit" className="btn-submit">Actualizar</button>
      </form>
      {data ? (
        <div className="metric-result">
          <ul>
            {Object.entries(data).map(([category, count]) => (
              <li key={category} className="category-item">
                <span className="category-name">{category}</span>
                <span className="category-count">{count} eventos</span>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>Cargando datos...</p>
      )}
    </div>
  );
};

export default TopCategoriesMetric;

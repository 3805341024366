import React from 'react';
import { Link } from 'react-router-dom';
import config from '../../configroutes';

const EventCard = ({ id_event, capacity, description, start_date, end_date, event_type, id_organizer, link_photo, location, participants, price, title, active}) => {
    const defaultImage = '../../images/evento_default_foto.png';  // Ruta de la imagen predeterminada

    const linkPhoto = link_photo && link_photo.includes(',')
    ? link_photo.split(',')[0].trim() // Tomar la primera URL
    : link_photo;

    const imageUrl = linkPhoto && !linkPhoto.endsWith('null')
        ? `${config.images_route}/${linkPhoto}` // Si existe linkPhoto, agregar ruta base
        : defaultImage; // Usar defaultImage sin ruta base
    
    const subtractFourHours = (dateString) => {
        const date = new Date(dateString);
        date.setHours(date.getHours() - 4);
        return new Date (date).toLocaleString(
          "es-ES",
          {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }
        )
      };
    
      const new_start_date = subtractFourHours(start_date);
      const new_end_date = subtractFourHours(end_date);

    return (
        <div className="bg-white rounded-lg shadow-lg overflow-hidden w-64 mx-auto transform transition-transform duration-200 hover:scale-105 hover:shadow-xl relative">
          {/* Estado de activación del evento */}
          <div className={`text-center py-1 font-semibold text-sm ${active ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'}`}>
            {active ? 'ACTIVO' : 'DESACTIVADO'}
          </div>
          {/* Imagen oprimible */}
          <Link to={`/event/${id_event}`} className="block h-48">
            <img 
              src={imageUrl} 
              alt={title} 
              className="w-full h-full object-cover" 
            />
          </Link>
    
          {/* Parte inferior: Información */}
          <div className="p-4">
            {/* Título oprimible */}
            <Link to={`/event/${id_event}`} className="text-xl font-bold mb-2 block text-blue-600 hover:underline">
              {title}
            </Link>
            <p className="text-gray-600">
              <strong>Fecha Inicio:</strong> {new_start_date}
            </p>
            <p className="text-gray-600">
              <strong>Fecha Término:</strong> {new_end_date}
            </p>
            <p className="text-gray-600">
              <strong>Ubicación:</strong> {location}
            </p>
          </div>
        </div>
      );
    };

export default EventCard;

import React from 'react';

const PopUpReport = ({ isOpen, onClose, title, reports }) => {
    if (!isOpen) return null;

    const subtractFourHours = (dateString) => {
        const date = new Date(dateString);
        date.setHours(date.getHours() - 4);
        return new Date(date).toLocaleString("es-ES", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        });
    };

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                <h3 className="text-2xl font-bold mb-4 text-center text-gray-800">{title}</h3>

                {/* Lista de Reports o Mensaje si no hay reportes */}
                <div 
                    className="space-y-4 overflow-y-auto" 
                    style={{ maxHeight: '300px' }} // Limitar la altura a un tamaño fijo
                >
                    {reports.length > 0 ? (
                        reports.map((report, index) => (
                            <div
                                key={index}
                                className="p-4 border-l-4 rounded-lg shadow-sm bg-gray-50 flex flex-col space-y-1 border-red-400"
                            >
                                <p className="text-sm font-semibold text-gray-700">
                                    Reportado por: <span className="text-gray-900">{report.reporter_user || 'Usuario desconocido'}</span>
                                </p>
                                <p className="text-sm text-gray-700">{report.reason || 'Sin descripción'}</p>
                                <p className="text-xs text-gray-500 mt-1">
                                    {subtractFourHours(report.created_at) || 'Fecha desconocida'}
                                </p>
                            </div>
                        ))
                    ) : (
                        <p className="text-center text-gray-500">No hay ninguna denuncia</p>
                    )}
                </div>

                <div className="flex justify-center mt-6">
                    <button
                        className="text-white bg-red-500 hover:bg-red-600 font-bold py-2 px-6 rounded-lg focus:outline-none"
                        onClick={onClose}
                    >
                        Cerrar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PopUpReport;

import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { checkAdminStatus } from '../../services/api';  // Importa la función desde api.js

const Login = () => {
  const { loginWithRedirect, isAuthenticated, isLoading, logout, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const verifyAdmin = async () => {
      try {
        const token = await getAccessTokenSilently();
        const isAdmin = await checkAdminStatus(token);  // Llama a la función de api.js

        if (isAdmin) {
          console.log('Acceso permitido: Usuario es administrador.');
          navigate('/main');
        } else {
          console.log('Acceso denegado: Usuario no es administrador.');
          setErrorMessage('No tienes permiso para acceder. Cerrando sesión...');
          setTimeout(() => {
            logout({ logoutParams: { returnTo: window.location.origin }});
            }, 1000);
        }
      } catch (error) {
        console.error('Error al verificar permisos de administrador:', error);
        setErrorMessage('Error al verificar permisos de administrador. Cerrando sesión...');
        setTimeout(() => {
          logout({ logoutParams: { returnTo: window.location.origin }});
          },1000);

      }
    };

    if (isAuthenticated) {
      verifyAdmin();
    }
  }, [isAuthenticated, navigate, logout, getAccessTokenSilently]);

  if (isLoading) return <div>Loading...</div>;

  if (!isAuthenticated) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-center">
          <h1 className="text-6xl font-bold text-gray-800 mb-8">Administrador Evexx</h1>
          <button
            className="bg-custom-primary hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full focus:outline-none focus:shadow-outline"
            onClick={loginWithRedirect}
          >
            Iniciar Sesión
          </button>
        </div>
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-red-600 mb-6">{errorMessage}</h1>
        </div>
      </div>
    );
  }

  return null;
};

export default Login;

import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getEventInfoById, getEventAttendees, getEventReview, getEventPosts, activateEvent, deactivateEvent, activateReview, deactivateReview, getEventReports, getReviewReports, getEventQuestions, deactivateQuestion, activateQuestion, getQuestionReports } from '../../services/api';
import { useAuth0 } from "@auth0/auth0-react";
import config from '../../configroutes';
import PopUpReport from '../../components/Report/PopUpReport';

const EventView = () => {
    const { id } = useParams();
    const { getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(true);
    const [event, setEvent] = useState(null);
    const [error, setError] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [attendees, setAttendees] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [posts, setPosts] = useState([]);
    const [isActive, setIsActive] = useState();
    const [eventReports, setEventReports] = useState([]);
    const [showReportsPopup, setShowReportsPopup] = useState(false);
    const [selectedReports, setSelectedReports] = useState([]);
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);

    const fetchEventInfo = async () => {
        try {
            setIsLoading(true);
            const token = await getAccessTokenSilently();
            const eventInfo = await getEventInfoById(token, id);
            const attendeeInfo = await getEventAttendees(token, id);
            const reviewInfo = await getEventReview(token, id);
            const postInfo = await getEventPosts(token, id);
            const reportsInfo = await getEventReports(token, id); 
            setIsActive(eventInfo.is_active);

            setEvent(eventInfo);
            setAttendees(attendeeInfo);
            setPosts(postInfo);
            setEventReports(reportsInfo);

            console.log(eventInfo)
            
            // Obtener reportes de cada reseña
            const reviewsWithReports = await Promise.all(
                reviewInfo.reviews.map(async (review) => {
                    const reviewReports = await getReviewReports(token, review.id_review);
                    return {
                        ...review,
                        reportCount: reviewReports.length,
                        reports: reviewReports,
                    };
                })
            );

            setReviews({ ...reviewInfo, reviews: reviewsWithReports });



        } catch (error) {
            console.error('Error al cargar la información del evento:', error.message);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchQuestions = async () => {
        try {
            const token = await getAccessTokenSilently();
            const questionsInfo = await getEventQuestions(token, id);

            const questionsWithReports = await Promise.all(
                questionsInfo.map(async (question) => {
                    const questionReports = await getQuestionReports(token, question.id_question);
                    return {
                        ...question,
                        reportCount: questionReports.length,
                        reports: questionReports,
                    };
                })
            );
    
            setQuestions(questionsWithReports);
            console.log(questionsWithReports);
        } catch (error) {
            console.error('Error al cargar las preguntas:', error.message);
        }
    };

    const handleQuestionStatus = async (questionId, questionActive) => {
        try {
            const token = await getAccessTokenSilently();
            if (questionActive) {
                console.log("Desactivar pregunta", questionId)
                await deactivateQuestion(token, questionId);
            } else {
                console.log("Activar pregunta", questionId)
                await activateQuestion(token, questionId);
            }

            // Actualizar solo la pregunta en el estado
            setQuestions(prevQuestions =>
                prevQuestions.map(question =>
                    question.id_question === questionId
                        ? { ...question, is_active: !questionActive }
                        : question
                )
            );
            await fetchEventInfo(); // Refresca la información del evento para actualizar las preguntas
        } catch (error) {
            console.error('Error al cambiar el estado de la pregunta:', error.message);
        }
    };

    const handleStatus = async () => {
        if (isActive && !window.confirm("¿Está seguro de que desea desactivar el evento?")) {
            return;
        }
        try {
            const token = await getAccessTokenSilently();
    
            if (isActive) {
                const deactivate = await deactivateEvent(token, id);
                setIsActive(false);
            } else {
                const activate = await activateEvent(token, id);
                setIsActive(true);
            }
    
            await fetchEventInfo(); // Actualizar la página
    
        } catch (error) {
            console.error('Error al cambiar el estado del evento:', error.message);
        }
    };

    const handleReviewStatus = async (reviewId, reviewActive) => {
        if (reviewActive && !window.confirm("¿Está seguro de que desea desactivar las reseñas?")) {
            return;
        }

        try {
            const token = await getAccessTokenSilently();
            if (reviewActive) {
                const deactivateRev = await deactivateReview(token, reviewId);
            } else {
                const activateRev = await activateReview(token, reviewId);
            }

            await fetchEventInfo();

        } catch (error) {
            console.error('Error al cambiar el estado de las reseñas:', error.message);
        }
    };

    const handleGoBack = () => {
        navigate(-1); // Esta función retrocede a la página anterior
    };


    useEffect(() => {
        fetchEventInfo();
        fetchQuestions();
    }, [id]);

    if (isLoading) {
        return <div className="py-10 flex items-center justify-center text-3xl font-semibold">Cargando evento...</div>;
    }

    if (error) {
        return <div className="py-10 flex items-center justify-center text-red-600">{`Error: ${error}`}</div>;
    }

    const photos = event?.link_photo
        ? event.link_photo.split(',').map(photo => photo.startsWith('http') ? photo : `${config.images_route}/${photo.trim()}`)
        : ['/images/evento_default_foto.png'];

    const totalImages = photos.length;

    const nextImage = () => setCurrentIndex((currentIndex + 1) % totalImages);
    const prevImage = () => setCurrentIndex((currentIndex - 1 + totalImages) % totalImages);

    const formatDate = (date) => {
        const options = { weekday: 'long', day: 'numeric', month: 'long', hour: '2-digit', minute: '2-digit' };
        const dateObj = new Date(date);
        dateObj.setHours(dateObj.getHours() - 4); // Restar 4 horas
        return dateObj.toLocaleString('es-ES', options);
    };

    return (
        <div className="max-w-4xl mx-auto p-4 bg-white shadow-md rounded-lg">
            <button onClick={handleGoBack} className="flex items-center text-gray-500 hover:text-black">
                    <span className="ml-2">Volver</span>
            </button>
            
            {/* Imagen del evento con botones de navegación */}
            <div className="w-full h-64 bg-white rounded-lg shadow-lg mb-4 z-20">
                <img
                    src={photos[currentIndex]}
                    alt={`Imagen ${currentIndex + 1}`}
                    className="w-full h-full object-cover rounded-lg"
                />
                {totalImages > 1 && (
                    <>
                        <button
                            onClick={prevImage}
                            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white px-3 py-2 rounded-full z-10"
                        >
                            &lt;
                        </button>
                        <button
                            onClick={nextImage}
                            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white px-3 py-2 rounded-full z-10"
                        >
                            &gt;
                        </button>
                        <div className="absolute bottom-4 right-4 flex space-x-1">
                            {photos.map((_, index) => (
                                <button
                                    key={index}
                                    onClick={() => setCurrentIndex(index)}
                                    className={`w-3 h-3 rounded-full ${index === currentIndex ? 'bg-blue-500' : 'bg-gray-400'}`}
                                />
                            ))}
                        </div>
                    </>
                )}
            </div>

            {/* Cantidad de denuncias */}
            <div className="flex flex-col items-center my-4">
                <div className={`flex items-center justify-center cursor-pointer rounded-lg px-4 py-2 shadow-lg max-w-xs text-center ${
                    eventReports.length === 0 ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'
                    }`}
                    onClick={() => { setSelectedReports(eventReports); setShowReportsPopup(true); }}
                >
                    <h3 className="text-lg font-semibold mr-2">Denuncias del Evento:</h3>
                    <p className="text-2xl font-bold">{eventReports.length}</p>
                </div>
            </div>
            
            {showReportsPopup && (
                <PopUpReport
                    isOpen={showReportsPopup}
                    onClose={() => setShowReportsPopup(false)}
                    title="Denuncias"
                    reports={selectedReports}
                />
            )}


            {/* Contenedor del estado, título y botón */}
            <div className="mb-4">
                <div className="flex items-center justify-between">
                    <span className={`text-lg font-medium py-1 px-2 rounded ${isActive ? 'bg-green-50 text-green-600 border border-green-300' : 'bg-red-50 text-red-600 border border-red-300'}`}>
                        {isActive ? 'EVENTO ACTIVO' : 'EVENTO DESACTIVADO'}
                    </span>
                    
                    <button
                        onClick={handleStatus}
                        className={`py-2 px-4 rounded-md text-white ${isActive ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'}`}
                    >
                        {isActive ? 'Desactivar Evento' : 'Activar Evento'}
                    </button>
                </div>
                <h1 className="text-3xl font-bold mt-2">{event.title}</h1>
            </div>

            <p className="text-gray-600 mb-4">{event.description}</p>

            <div className="flex items-center space-x-2 mb-4">
                <p className="text-md font-semibold">Organizado por:</p>
                    <Link to={`/profile/${event.id_organizer}`} className="text-black-600 underline">
                        {event.organizer}
                    </Link>
            </div>

            <div className="flex items-center space-x-2 mb-4">
                <p>{event.event_type === 'public' ? 'Evento Público' : 'Evento Privado'}</p>
            </div>

            {/* Categoría del evento */}
            <div className="flex items-center space-x-2 mb-4">
                <span className="bg-blue-100 text-blue-800 font-medium px-3 py-1 rounded-full shadow-sm">
                    {event.categories?.[0]?.name || 'Sin categoría'}
                </span>
            </div>

            {/* Horario y ubicación */}
            <div className="flex items-center space-x-2 mb-4">
                <p>{`Horario: ${formatDate(event.start_date)} - ${formatDate(event.end_date)}`}</p>
            </div>
            <div className="flex items-center space-x-2 mb-4">
                <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(event.location)}`} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">
                    {event.location}
                </a>
            </div>

            {/* Capacidad y precio */}
            <div className="flex items-center space-x-2 mb-4">
                <p>{`${event.participants} de ${event.capacity} asistentes`}</p>
            </div>
            <div className="flex items-center space-x-2 mb-4">
                <p className="text-md font-semibold">{`Precio: ${event.price ? `$${event.price}` : 'Gratis'}`}</p>
            </div>

            {/* Sección de asistentes */}
            <h2 className="text-xl font-semibold mt-6">Asistentes</h2>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mb-6">
                {attendees.attendees && attendees.attendees.length > 0 ? (
                    attendees.attendees.map((attendee, index) => (
                        <Link 
                            to={`/profile/${attendee.id_user}`} // Redirige a la página de perfil del asistente
                            key={index}
                            className="bg-gray-100 rounded-lg shadow p-3 flex items-center justify-center text-center hover:bg-gray-200 transition duration-300"
                        >
                            <p className="font-medium text-gray-800">{attendee.name}</p>
                        </Link>
                    ))
                ) : (
                    <p className="col-span-full text-left text-gray-600">No hay asistentes confirmados.</p>
                )}
            </div>

            {/* Sección de avisos */}
            <h2 className="text-xl font-semibold mt-6">Avisos</h2>
            <div className="space-y-4">
                {posts.total_posts > 0 ? (
                    posts.posts.map((post, index) => (
                        <div key={index} className="p-6 border rounded-lg shadow-md bg-white">
                            <h3 className="text-lg font-bold mb-2">{post.title}</h3>
                            <p className="text-gray-700 mb-4">{post.content}</p>
                            <p className="text-sm text-gray-500">Publicado el: {new Date(post.created_at).toLocaleDateString('es-ES')}</p>
                        </div>
                    ))
                ) : (
                    <p>No hay posts.</p>
                )}
            </div>

            {/* Sección de reseñas */}
            <h2 className="text-xl font-semibold mt-6">Reseñas</h2>
            <div className="space-y-4">
                {reviews.total_reviews > 0 ? (
                    reviews.reviews.map((review, index) => (
                        <div key={index} className="p-4 border rounded shadow-sm bg-gray-50">
                            <div className="flex items-center justify-between mb-2">
                                {/* Indicador de estado de la reseña */}
                                <span className={`font-bold text-lg ${review.is_active ? 'text-green-600' : 'text-red-600'}`}>
                                    {review.is_active ? 'ACTIVA' : 'DESACTIVADA'}
                                </span>
                    
                                <button
                                    onClick={() => handleReviewStatus(review.id_review, review.is_active)}
                                    className={`py-1 px-2 rounded text-white ${review.is_active ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'}`}
                                >
                                    {review.is_active ? 'Desactivar' : 'Activar'}
                                </button>
                            </div>
                
                            {/* Mostrar cantidad de denuncias */}
                            <div
                                onClick={() => { setSelectedReports(review.reports); setShowReportsPopup(true); }}
                                className="flex items-center cursor-pointer space-x-2 mb-2 text-sm font-semibold"
                            >
                                <span
                                    className={`px-2 py-1 rounded-full ${
                                        review.reportCount === 0 ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'
                                    }`}
                                >
                                    {`Cantidad de denuncias: ${review.reportCount}`}
                                </span>
                            </div>

                            <p className="font-bold text-lg">{review.user_name}</p>
            
                            <div className="flex items-center">
                                {Array.from({ length: review.rate }).map((_, i) => (
                                    <span key={i} className="text-yellow-400">★</span>
                                ))}
                                {Array.from({ length: 5 - review.rate }).map((_, i) => (
                                    <span key={i} className="text-gray-300">★</span>
                                ))}
                            </div>
                            <p className="text-gray-700 mt-2">{review.content}</p>
                            <p className="text-sm text-gray-500 mt-2">{new Date(review.created_at).toLocaleDateString('es-ES')}</p>
                        </div>
                    ))
                ) : (
                    <p>No hay reseñas disponibles.</p>
                )}
            </div>

            {/* Sección de preguntas */}
            <h2 className="text-xl font-semibold mt-8 mb-4 text-gray-800">Preguntas</h2>
            <div className="space-y-4">
                {questions.length > 0 ? (
                    questions.map((question, index) => (
                        <div key={index} className="p-4 border border-gray-200 rounded-md bg-gray-50 shadow-sm hover:shadow-md transition-shadow duration-300">
                            <div className="flex items-center justify-between">
                                {/* Estado de la pregunta */}
                                <span className={`min-w-[150px] font-bold text-l ${question.is_active ? 'text-green-600' : 'text-red-600'} mr-4`}>
                                    {question.is_active ? 'ACTIVA' : 'DESACTIVADA'}
                                </span>

                                {/* Cantidad de denuncias */}
                                <div
                                    onClick={() => { setSelectedReports(question.reports); setShowReportsPopup(true); }}
                                    className="flex items-center cursor-pointer space-x-2 mb-2 text-sm font-semibold"
                                >
                                    <span
                                        className={`px-2 py-1 rounded-full ${
                                            question.reportCount === 0 ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'
                                        }`}
                                    >
                                        {`Cantidad de denuncias: ${question.reportCount}`}
                                    </span>
                                </div>

                                {/* Botón para activar o desactivar la pregunta */}
                                <button
                                    onClick={() => handleQuestionStatus(question.id_question, question.is_active)}
                                    className={`py-1 px-2 rounded text-white ${question.is_active ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'}`}
                                >
                                    {question.is_active ? 'Desactivar' : 'Activar'}
                                </button>
                            </div>

                            {/* Contenido de la pregunta */}
                            <div className="flex flex-col">
                                <p className="text-gray-800 font-medium text-sm">{question.question}</p>
                                <div className="text-xs text-gray-400 mt-1">
                                    {`${new Date(question.created_at).toLocaleDateString('es-ES', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                    })}`}
                                </div>
                            </div>

                            {/* Respuesta a la pregunta */}
                            <div className="mt-2 ml-6 text-sm">
                                {question.reply ? (
                                    <>
                                        <p className="text-gray-700 border-l-4 border-blue-500 pl-2">{question.reply}</p>
                                        <p className="text-xs text-gray-400 mt-1">
                                            {`${new Date(question.updated_at).toLocaleDateString('es-ES', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                            })}`}
                                        </p>
                                    </>
                                ) : (
                                    <p className="text-gray-500 italic">Aún no tiene respuesta</p>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-gray-500 italic">No hay preguntas.</p>
                )}
            </div>
        </div>
    );
};

export default EventView;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChartBar, FaClipboardList } from 'react-icons/fa';
import { ImBlocked } from 'react-icons/im';
import './AdminDashboard.css';

const AdminDashboard = () => {
  const navigate = useNavigate();

  return (
    <div className="admin-dashboard-container min-h-screen bg-gray-100 flex flex-col items-center justify-center">
      <h1 className="admin-dashboard-title text-5xl font-bold mb-8 text-custom-secondary text-center">
        Panel de Administración
      </h1>
      <div className="admin-dashboard-cards flex flex-col space-y-6 items-center w-full max-w-2xl">
        {/* Tarjeta Métricas */}
        <div
          onClick={() => navigate('/metrics')}
          className="admin-dashboard-card bg-white p-6 rounded-lg shadow-md hover:shadow-lg cursor-pointer transition-shadow duration-300 w-full"
        >
          <FaChartBar className="admin-card-icon text-green-700 text-4xl mb-4" />
          <div className="admin-card-content text-center">
            <h2 className="admin-card-title text-xl font-bold mb-2">Métricas</h2>
            <p className="admin-card-description text-gray-600">
              Ver estadísticas de uso
            </p>
          </div>
        </div>

        {/* Contenedor para las dos tarjetas inferiores */}
        <div className="admin-dashboard-subcards flex flex-row space-x-6 w-full">
          {/* Tarjeta Reportes */}
          <div
            onClick={() => navigate('/reports')}
            className="admin-dashboard-card bg-white p-6 rounded-lg shadow-md hover:shadow-lg cursor-pointer transition-shadow duration-300 flex-1"
          >
            <FaClipboardList className="admin-card-icon text-gray-700 text-4xl mb-4" />
            <div className="admin-card-content text-center">
              <h2 className="admin-card-title text-xl font-bold mb-2">Reportes</h2>
              <p className="admin-card-description text-gray-600">
                Gestionar Reportes del sistema
              </p>
            </div>
          </div>

          {/* Tarjeta Usuarios Inactivos */}
          <div
            onClick={() => navigate('/inactive')}
            className="admin-dashboard-card bg-white p-6 rounded-lg shadow-md hover:shadow-lg cursor-pointer transition-shadow duration-300 flex-1"
          >
            <ImBlocked className="admin-card-icon text-red-500 text-4xl mb-4" />
            <div className="admin-card-content text-center">
              <h2 className="admin-card-title text-xl font-bold mb-2">
                Usuarios Inactivos
              </h2>
              <p className="admin-card-description text-gray-600">
                Administrar Usuarios Desactivados
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;

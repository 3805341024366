import React, { useEffect, useState, useCallback } from 'react';
import { getArrivalsThroughOtherPersonMetrics } from '../../../services/api';
import { useAuth0 } from '@auth0/auth0-react';
import './ArrivalsThroughOtherPersonMetric.css';

const ArrivalsThroughOtherPersonMetric = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetchMetrics = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const result = await getArrivalsThroughOtherPersonMetrics(token);
      setData(result);
    } catch (err) {
      console.error('Error obteniendo métricas de llegadas a través de otra persona:', err);
      setError('No se pudieron obtener las métricas de llegadas a través de otra persona.');
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  return (
    <div className="arrivals-other-person metric-card">
      <h2>Llegadas por Referencia de Otra Persona</h2>
      {error ? (
        <p>{error}</p>
      ) : data ? (
        <p>Cantidad de usuarios que llegaron por otra persona: <strong>{data.count}</strong></p>
      ) : (
        <p>Cargando datos...</p>
      )}
      <button onClick={fetchMetrics} className="btn-refresh">Actualizar</button>
    </div>
  );
};

export default ArrivalsThroughOtherPersonMetric;

import React, { useEffect, useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getInactiveUsers } from '../../../services/api';
import './InactiveUsers.css';

const DEFAULT_PROFILE_IMAGE = '/images/imagen_perfil_default.png';

const InactiveUsers = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState(null);

  const fetchInactiveUsers = useCallback(async (page) => {
    try {
      const token = await getAccessTokenSilently();
      const data = await getInactiveUsers(token, page);
      setUsers(data.users);
      setCurrentPage(data.current_page);
      setTotalPages(data.total_pages);
    } catch (err) {
      console.error('Error obteniendo usuarios desactivados:', err);
      setError(err.message);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchInactiveUsers(currentPage);
  }, [fetchInactiveUsers, currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleRowClick = (id) => {
    window.open(`/profile/${id}`, '_blank');
  };

  return (
    <div className="inactive-users-container p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-xl font-bold mb-4">Usuarios Desactivados</h2>

      {error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div>
          {users.length > 0 ? (
            <table className="table-auto w-full border-collapse border border-gray-300">
              <thead>
                <tr>
                  <th className="border px-4 py-2">ID</th>
                  <th className="border px-4 py-2">Nombre</th>
                  <th className="border px-4 py-2">Correo</th>
                  <th className="border px-4 py-2">Foto de Perfil</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr
                    key={user.id_user}
                    className="hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleRowClick(user.id_user)}
                  >
                    <td className="border px-4 py-2">{user.id_user}</td>
                    <td className="border px-4 py-2">{user.name}</td>
                    <td className="border px-4 py-2">{user.email}</td>
                    <td className="border px-4 py-2 text-center">
                      <img
                        src={
                          user.profile_link
                            ? `${process.env.REACT_APP_IMAGE_CLOUD_URL}/${user.profile_link}`
                            : DEFAULT_PROFILE_IMAGE
                        }
                        alt={user.name}
                        className="h-10 w-10 rounded-full mx-auto"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No hay usuarios desactivados.</p>
          )}
        </div>
      )}

      {users.length > 0 && (
        <div className="flex justify-between mt-4">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded ${
              currentPage === 1
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-blue-500 text-white hover:bg-blue-600'
            }`}
          >
            Anterior
          </button>
          <span>
            Página {currentPage} de {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 rounded ${
              currentPage === totalPages
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-blue-500 text-white hover:bg-blue-600'
            }`}
          >
            Siguiente
          </button>
        </div>
      )}
    </div>
  );
};

export default InactiveUsers;

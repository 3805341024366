import React, { useEffect, useState, useCallback } from 'react';
import { getUsersMostEventsMetrics } from '../../../services/api';
import { useAuth0 } from '@auth0/auth0-react';
import './UsersMostEventsMetric.css';

const UsersMostEventsMetric = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [limit, setLimit] = useState(3);

  const fetchMetrics = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const result = await getUsersMostEventsMetrics(token, limit);
      setData(result);
    } catch (err) {
      console.error('Error obteniendo métricas de usuarios con más eventos:', err);
      setError('No se pudieron obtener las métricas de usuarios con más eventos.');
    }
  }, [getAccessTokenSilently, limit]);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setData(null);
    setError(null);
    fetchMetrics();
  };

  if (error) return <div className="users-events-metric metric-card">{error}</div>;

  return (
    <div className="users-events-metric metric-card">
      <h2>Usuarios con Más Eventos</h2>
      <form onSubmit={handleSubmit} className="metric-form">
        <label>
          Límite:
          <input
            type="number"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
            min="1"
            className="input-field"
          />
        </label>
      </form>
      {data ? (
        <div className="metric-result">
          <div className="followers-column">
            <h3>Organizadores:</h3>
            <ul>
              {data.organizers.map((org) => (
                <li key={org.email} className="user-item">
                  <span className="user-name">{org.name}</span>
                  <span className="user-email">{org.email}</span>
                  <span className="user-events">{org.number_events} eventos</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="followers-column">
            <h3>Usuarios:</h3>
            <ul>
              {data.regular_users.map((user) => (
                <li key={user.email} className="user-item">
                  <span className="user-name">{user.name}</span>
                  <span className="user-email">{user.email}</span>
                  <span className="user-events">{user.number_events} eventos</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <p>Cargando datos...</p>
      )}
    </div>
  );
};

export default UsersMostEventsMetric;

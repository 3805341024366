import React, { useState, useEffect, useRef } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  RiAdminFill,
  RiBarChart2Line,
  RiFileList2Line,
  RiLogoutBoxLine,
} from "react-icons/ri";
import { ImBlocked } from "react-icons/im"; // Ícono para Inactive
import AdminSearchBar from "../SearchBar/SearchBar";

const NavBar = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  // Función para cerrar el dropdown al hacer clic fuera
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  return (
    <div className="bg-custom-primary text-white py-1 px-2">
      <nav className="flex items-center justify-between py-1 px-2 relative">
        {/* Contenedor para ícono de admin, que solo aparece cuando está autenticado */}
        {isAuthenticated && (
          <div className="flex items-center">
            <RouterNavLink
              to="/main"
              className="flex items-center"
              title="Volver al Inicio"
            >
               <img src="/images/logo_sintexto.png" alt="Home" className="max-h-9 w-auto mr-6" /> {/* Logo */}
            </RouterNavLink>
          </div>
        )}

        {/* Espacio flexible para empujar el contenido a la derecha */}
        <div className="flex-grow"></div>

        {/* Barra de búsqueda */}
        {isAuthenticated && (
          <div className="absolute left-1/2 transform -translate-x-1/2">
            <AdminSearchBar />
          </div>
        )}

        {/* Opciones a la derecha */}
        <div className="flex items-center space-x-4">
          {!isAuthenticated ? (
            <button
              className="bg-white bg-opacity-30 border-2 border-white text-blue-700 py-1 px-4 rounded-full transition-all duration-300 hover:bg-white hover:text-blue-700 hover:font-bold"
              onClick={loginWithRedirect}
            >
              Iniciar Sesión
            </button>
          ) : (
            <>
              {/* Métricas */}
              <RouterNavLink
                to="/metrics"
                className={({ isActive }) =>
                  `flex flex-col items-center hover:text-yellow-500 ${
                    isActive
                      ? "text-yellow-500 border-b-2 border-yellow-500"
                      : ""
                  }`
                }
              >
                <RiBarChart2Line className="w-6 h-6 mb-0" />
                <span className="text-xs">Métricas</span>
              </RouterNavLink>

              {/* Reportes */}
              <RouterNavLink
                to="/reports"
                className={({ isActive }) =>
                  `flex flex-col items-center hover:text-yellow-500 ${
                    isActive
                      ? "text-yellow-500 border-b-2 border-yellow-500"
                      : ""
                  }`
                }
              >
                <RiFileList2Line className="w-6 h-6 mb-0" />
                <span className="text-xs">Reportes</span>
              </RouterNavLink>

              {/* Inactive */}
              <RouterNavLink
                to="/inactive"
                className={({ isActive }) =>
                  `flex flex-col items-center hover:text-yellow-500 ${
                    isActive
                      ? "text-yellow-500 border-b-2 border-yellow-500"
                      : ""
                  }`
                }
              >
                <ImBlocked className="w-6 h-6 mb-0" />
                <span className="text-xs">Inactivos</span>
              </RouterNavLink>

              {/* Cerrar Sesión */}
              <button
                onClick={logoutWithRedirect}
                className="flex flex-col items-center hover:text-yellow-500"
              >
                <RiLogoutBoxLine className="w-6 h-6 mb-0" />
                <span className="text-xs">Cerrar Sesión</span>
              </button>
            </>
          )}
        </div>
      </nav>
    </div>
  );
};

export default NavBar;

import React, { useEffect, useState, useCallback } from 'react';
import { getUsersAttendanceMetrics } from '../../../services/api';
import { useAuth0 } from '@auth0/auth0-react';
import './UsersAttendanceMetric.css';

const UsersAttendanceMetric = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [months, setMonths] = useState(3);
  const [minEvents, setMinEvents] = useState(3);

  const fetchMetrics = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const result = await getUsersAttendanceMetrics(token, months, minEvents);
      setData(result);
    } catch (err) {
      console.error('Error obteniendo métricas de asistencia de usuarios:', err);
      setError('No se pudieron obtener las métricas de asistencia de usuarios.');
    }
  }, [getAccessTokenSilently, months, minEvents]);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setData(null);
    setError(null);
    fetchMetrics();
  };

  if (error) return <div className="metric-card">{error}</div>;

  return (
    <div className="metric-card">
      <h2>Métricas de Asistencia de Usuarios</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Meses:
          <input
            type="number"
            value={months}
            onChange={(e) => setMonths(e.target.value)}
            min="1"
            className="input-field"
          />
        </label>
        <label>
          Mínimo de Eventos:
          <input
            type="number"
            value={minEvents}
            onChange={(e) => setMinEvents(e.target.value)}
            min="1"
            className="input-field"
          />
        </label>
      </form>
      {data ? (
        <div className="metric-result">
          <h3>Usuarios con Mínimo de {minEvents} Eventos en los Últimos {months} Meses:</h3>
          <p>{data.users_with_min_events}</p>
        </div>
      ) : (
        <p>Cargando datos...</p>
      )}
    </div>
  );
};

export default UsersAttendanceMetric;

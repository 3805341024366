import React, { useEffect, useState, useCallback } from 'react';
import { getEventsWithReportsAboveLimit } from '../../../services/api';
import { useAuth0 } from '@auth0/auth0-react';
import './EventsWithReportsMetric.css';

const EventsWithReportsMetric = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [limit, setLimit] = useState(3); // Estado para manejar el límite

  const fetchMetrics = useCallback(async (currentLimit) => {
    try {
      const token = await getAccessTokenSilently();
      const result = await getEventsWithReportsAboveLimit(token, currentLimit);
      setData(result);
    } catch (err) {
      console.error('Error obteniendo eventos con reportes por encima del límite:', err);
      setError('No se pudieron obtener los datos de eventos con reportes.');
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchMetrics(limit);
  }, [fetchMetrics, limit]);

  const handleRowClick = (id) => {
    window.open(`/event/${id}`, '_blank'); // Abrir en una nueva pestaña
  };

  return (
    <div className="events-reports controlmetric-card">
      <header className="events-reports-header">
        <h2>Eventos con {limit + 1} o más Reportes</h2>
        <div className="limit-selector">
          <label htmlFor="limit">Límite de reportes:</label>
          <input
            id="limit"
            type="number"
            min="1"
            value={limit + 1} // Mostrar el valor incrementado en 1
            onChange={(e) => setLimit(Number(e.target.value) - 1)} // Almacenar el valor decrementado en 1
            className="limit-input"
          />
        </div>
      </header>

      {error ? (
        <p className="error-message">{error}</p>
      ) : data && data.events ? (
        data.events.length > 0 ? (
          <section className="table-section">
            <table className="reports-table">
              <thead>
                <tr>
                  <th>ID Evento</th>
                  <th>Título</th>
                  <th>Descripción</th>
                  <th>Organizador</th>
                  <th>Reportes</th>
                </tr>
              </thead>
              <tbody>
                {data.events.map((event) => (
                  <tr key={event.id_event} onClick={() => handleRowClick(event.id_event)} className="clickable-row">
                    <td>{event.id_event}</td>
                    <td>{event.title}</td>
                    <td>{event.description}</td>
                    <td>{event.organizer}</td>
                    <td>{event.number_reports}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        ) : (
          <p>No hay eventos que cumplan con el límite establecido.</p>
        )
      ) : (
        <p>Cargando datos...</p>
      )}
    </div>
  );
};

export default EventsWithReportsMetric;

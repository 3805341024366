import React, { useEffect, useState, useCallback } from 'react';
import { getQuestionsWithReportsAboveLimit } from '../../../services/api';
import { useAuth0 } from '@auth0/auth0-react';
import './QuestionsWithReportsMetric.css';

const QuestionsWithReportsMetric = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [limit, setLimit] = useState(3); // Estado para manejar el límite

  const fetchMetrics = useCallback(async (currentLimit) => {
    try {
      const token = await getAccessTokenSilently();
      const result = await getQuestionsWithReportsAboveLimit(token, currentLimit);
      setData(result);
    } catch (err) {
      console.error('Error obteniendo preguntas con reportes por encima del límite:', err);
      setError('No se pudieron obtener los datos de preguntas con reportes.');
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchMetrics(limit);
  }, [fetchMetrics, limit]);

  const handleOpenUser = (id) => {
    window.open(`/profile/${id}`, '_blank'); // Abrir el perfil del usuario en una nueva pestaña
  };

  const handleOpenEvent = (id) => {
    window.open(`/event/${id}`, '_blank'); // Abrir el evento en una nueva pestaña
  };

  return (
    <div className="questions-reports controlmetric-card">
      <header className="questions-reports-header">
        <h2>Preguntas con {limit + 1} o más Reportes</h2>
        <div className="limit-selector">
          <label htmlFor="limit">Límite de reportes:</label>
          <input
            id="limit"
            type="number"
            min="1"
            value={limit + 1} // Mostrar el valor incrementado en 1
            onChange={(e) => setLimit(Number(e.target.value) - 1)} // Almacenar el valor decrementado en 1
            className="limit-input"
          />
        </div>
      </header>

      {error ? (
        <p className="error-message">{error}</p>
      ) : data && data.questions ? (
        data.questions.length > 0 ? (
          <section className="table-section">
            <table className="reports-table">
              <thead>
                <tr>
                  <th>ID Pregunta</th>
                  <th>Contenido</th>
                  <th>ID Evento</th>
                  <th>Usuario</th>
                  <th>Reportes</th>
                </tr>
              </thead>
              <tbody>
                {data.questions.map((question) => (
                  <tr key={question.id_question}>
                    <td>{question.id_question}</td>
                    <td>{question.content}</td>
                    <td>
                      <span
                        onClick={() => handleOpenEvent(question.id_event)}
                        className="clickable-link"
                      >
                        {question.id_event}
                      </span>
                    </td>
                    <td>
                      <span
                        onClick={() => handleOpenUser(question.id_user)}
                        className="clickable-link"
                      >
                        {question.user}
                      </span>
                    </td>
                    <td>{question.number_reports}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        ) : (
          <p>No hay preguntas que cumplan con el límite establecido.</p>
        )
      ) : (
        <p>Cargando datos...</p>
      )}
    </div>
  );
};

export default QuestionsWithReportsMetric;

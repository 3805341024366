import React, { useEffect, useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getInactiveReviews } from '../../../services/api';
import './InactiveReviews.css';

const InactiveReviews = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState(null);

  const fetchInactiveReviews = useCallback(async (page) => {
    try {
      const token = await getAccessTokenSilently();
      const data = await getInactiveReviews(token, page);
      setReviews(data.reviews);
      setCurrentPage(data.current_page);
      setTotalPages(data.total_pages);
    } catch (err) {
      console.error('Error obteniendo reseñas desactivadas:', err);
      setError(err.message);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchInactiveReviews(currentPage);
  }, [fetchInactiveReviews, currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <div className="inactive-reviews-container p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-xl font-bold mb-4">Reseñas Desactivadas</h2>

      {error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div>
          {reviews.length > 0 ? (
            <table className="table-auto w-full border-collapse border border-gray-300">
              <thead>
                <tr>
                  <th className="border px-4 py-2">Contenido</th>
                  <th className="border px-4 py-2">Calificación</th>
                  <th className="border px-4 py-2">Evento</th>
                  <th className="border px-4 py-2">Usuario</th>
                </tr>
              </thead>
              <tbody>
                {reviews.map((review) => (
                  <tr key={review.id_review} className="hover:bg-gray-100">
                    <td className="border px-4 py-2">{review.content}</td>
                    <td className="border px-4 py-2 text-center">{review.rate}</td>
                    <td className="border px-4 py-2 text-blue-500 underline cursor-pointer">
                      <span
                        onClick={() => window.open(`/event/${review.id_event}`, '_blank')}
                      >
                        {review.event}
                      </span>
                    </td>
                    <td className="border px-4 py-2 text-blue-500 underline cursor-pointer">
                      <span
                        onClick={() => window.open(`/profile/${review.id_user}`, '_blank')}
                      >
                        {review.user}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No hay reseñas desactivadas.</p>
          )}
        </div>
      )}

      {reviews.length > 0 && (
        <div className="flex justify-between mt-4">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded ${
              currentPage === 1
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-blue-500 text-white hover:bg-blue-600'
            }`}
          >
            Anterior
          </button>
          <span>
            Página {currentPage} de {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 rounded ${
              currentPage === totalPages
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-blue-500 text-white hover:bg-blue-600'
            }`}
          >
            Siguiente
          </button>
        </div>
      )}
    </div>
  );
};

export default InactiveReviews;

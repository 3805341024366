import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import NewAccountsMetric from './Components/NewAccountsMetric';
import UsersAttendanceMetric from './Components/UsersAttendanceMetric';
import UsersMostFollowersMetric from './Components/UsersMostFollowersMetric';
import UsersMostEventsMetric from './Components/UsersMostEventsMetric';
import TopCategoriesMetric from './Components/TopCategoriesMetric';
import TopCategoriesByAttendeesMetric from './Components/TopCategoriesByAttendeesMetric';
import PublicVisibilityPercentageMetric from './Components/PublicVisibilityPercentageMetric';
import ArrivalsThroughOtherPersonMetric from './Components/ArrivalsThroughOtherPersonMetric';
import TopEventTimesAndPlacesMetric from './Components/TopEventTimesAndPlacesMetric';
import './Metrics.css';

const Metrics = () => {
  const navigate = useNavigate(); // Crear instancia de navigate

  const handleGoBack = () => {
    navigate(-1); // Navegar hacia la página anterior
  };

  return (
    <div className="dashboard">
      <header className="dashboard-header">
        <p onClick={handleGoBack} className="go-back-text">
          Volver
        </p>
        <h1>Panel de Métricas del Administrador</h1>
      </header>
      <div className="dashboard-content">
        {/* Métricas de Usuarios */}
        <section className="metric-section">
          <h2>Métricas de Usuarios</h2>
          <div className="metric-cards">
            <NewAccountsMetric />
            <UsersMostFollowersMetric />
            <UsersMostEventsMetric />
            <UsersAttendanceMetric />
            <PublicVisibilityPercentageMetric />
            <ArrivalsThroughOtherPersonMetric />
          </div>
        </section>

        {/* Métricas de Eventos, Categorías y Lugares */}
        <section className="metric-section">
          <h2>Métricas de Eventos, Categorías y Lugares</h2>
          <div className="metric-cards">
            <TopCategoriesMetric />
            <TopCategoriesByAttendeesMetric />
            <TopEventTimesAndPlacesMetric />
          </div>
        </section>
      </div>
    </div>
  );
};

export default Metrics;

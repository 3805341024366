import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar/navbar';
import Home from './views/Home/Home';
import NotFoundpage from './views/NotFoundpage';
import AdminDashboard from './views/admin/AdminDashboard'; 
import EventView from './views/Events/Event.jsx';
import Metrics from './views/Metrics/Metrics';
import ProfileView from './views/Profiles/Profile';
import Reports from './views/Reports/control_panel';
import Inactive from './views/Inactive/Inactive';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

const protectedRoutes = [
  { path: '/main', element: <AdminDashboard /> },
  { path: '/metrics', element: <Metrics /> },
  { path: '/event/:id', element: <EventView /> },
  { path: '/profile/:id', element: <ProfileView /> },
  { path: '/reports', element: <Reports /> },
  { path: '/inactive', element: <Inactive /> },
];

function App() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        {protectedRoutes.map(({ path, element }) => (
          <Route 
            key={path} 
            path={path} 
            element={<ProtectedRoute>{element}</ProtectedRoute>} 
          />
        ))}
        <Route path="*" element={<NotFoundpage />} />
      </Routes>
    </>
  );
}

export default App;

import React, { useEffect, useState, useCallback } from 'react';
import { getTopCategoriesByAttendeesMetrics } from '../../../services/api';
import { useAuth0 } from '@auth0/auth0-react';
import './TopCategoriesByAttendeesMetric.css';

const TopCategoriesByAttendeesMetric = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [limit, setLimit] = useState(5);

  const fetchMetrics = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const result = await getTopCategoriesByAttendeesMetrics(token, limit);
      //console.log('Datos obtenidos:', result); // Confirmación en consola de datos obtenidos
      setData(result);
    } catch (err) {
      console.error('Error obteniendo métricas de categorías populares por asistentes:', err);
      setError('No se pudieron obtener las métricas de categorías populares por asistentes.');
    }
  }, [getAccessTokenSilently, limit]);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setData(null); // Limpia los datos para nueva búsqueda
    setError(null); // Limpia errores anteriores
    fetchMetrics();
  };

  if (error) return <div className="metric-card">{error}</div>;

  const historicalEvents = data?.top_categories_by_attendees?.historical_events || [];
  const upcomingEvents = data?.top_categories_by_attendees?.upcoming_events || [];

  return (
    <div className="metric-card">
      <h2>Categorías Populares por Asistentes</h2>
      <form onSubmit={handleSubmit} className="metric-form">
        <label>
          Límite:
          <input
            type="number"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
            min="1"
            className="input-field"
          />
        </label>
        <button type="submit" className="btn-submit">Actualizar</button>
      </form>
      {data ? (
        <div className="metric-result">
          <h3>Eventos Históricos:</h3>
          <ul>
            {historicalEvents.map((category) => (
              <li key={category.category_name} className="category-item">
                <span className="category-name">{category.category_name}</span>
                <span className="category-count">{category.total_attendees} asistentes</span>
              </li>
            ))}
          </ul>
          <h3>Eventos Futuros:</h3>
          <ul>
            {upcomingEvents.map((category) => (
              <li key={category.category_name} className="category-item">
                <span className="category-name">{category.category_name}</span>
                <span className="category-count">{category.total_attendees} asistentes</span>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>Cargando datos...</p>
      )}
    </div>
  );
};

export default TopCategoriesByAttendeesMetric;

import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importamos useNavigate
import InactiveUsers from './Components/InactiveUsers';
import InactiveEvents from './Components/InactiveEvents';
import InactiveReviews from './Components/InactiveReviews';
import InactiveQuestions from './Components/InactiveQuestions';
import './Inactive.css';

const Inactive = () => {
  const navigate = useNavigate(); // Inicializamos navigate

  return (
    <div className="inactive-page-container p-6 bg-gray-100">
      {/* Botón de volver */}
      <button
        onClick={() => navigate(-1)} // Navegar a la página anterior
        className="text-blue-500 hover:underline mb-4"
      >
        Volver
      </button>

      {/* Título principal */}
      <h1 className="text-2xl font-bold mb-6">Administrar Entidades Desactivadas</h1>

      {/* Sección con espaciado vertical entre componentes */}
      <div className="inactive-section space-y-6">
        <InactiveUsers />
        <InactiveEvents />

        <InactiveReviews /> 
        
        <InactiveQuestions /> 
      </div>
    </div>
  );
};

export default Inactive;

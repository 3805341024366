import React, { useEffect, useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getInactiveEvents } from '../../../services/api';
import './InactiveEvents.css';

const DEFAULT_EVENT_IMAGE = '/images/evento_default_foto.png';

const InactiveEvents = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState(null);

  const fetchInactiveEvents = useCallback(async (page) => {
    try {
      const token = await getAccessTokenSilently();
      const data = await getInactiveEvents(token, page);
      setEvents(data.events);
      setCurrentPage(data.current_page);
      setTotalPages(data.total_pages);
    } catch (err) {
      console.error('Error obteniendo eventos desactivados:', err);
      setError(err.message);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchInactiveEvents(currentPage);
  }, [fetchInactiveEvents, currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleRowClick = (id) => {
    window.open(`/event/${id}`, '_blank');
  };

  return (
    <div className="inactive-events-container p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-xl font-bold mb-4">Eventos Desactivados</h2>

      {error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div>
          {events.length > 0 ? (
            <table className="table-auto w-full border-collapse border border-gray-300">
              <thead>
                <tr>
                  <th className="border px-4 py-2">ID</th>
                  <th className="border px-4 py-2">Título</th>
                  <th className="border px-4 py-2">Descripción</th>
                  <th className="border px-4 py-2">Organizador</th>
                  <th className="border px-4 py-2">Foto</th>
                </tr>
              </thead>
              <tbody>
                {events.map((event) => (
                  <tr
                    key={event.id_event}
                    className="hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleRowClick(event.id_event)}
                  >
                    <td className="border px-4 py-2">{event.id_event}</td>
                    <td className="border px-4 py-2">{event.title}</td>
                    <td className="border px-4 py-2">{event.description}</td>
                    <td className="border px-4 py-2">{event.organizer}</td>
                    <td className="border px-4 py-2 text-center">
                      <img
                        src={
                          event.link_photo
                            ? `${process.env.REACT_APP_IMAGE_CLOUD_URL}/${event.link_photo}`
                            : DEFAULT_EVENT_IMAGE
                        }
                        alt={event.title}
                        className="h-10 w-10 rounded-full mx-auto"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No hay eventos desactivados.</p>
          )}
        </div>
      )}

      {events.length > 0 && (
        <div className="flex justify-between mt-4">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded ${
              currentPage === 1
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-blue-500 text-white hover:bg-blue-600'
            }`}
          >
            Anterior
          </button>
          <span>
            Página {currentPage} de {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 rounded ${
              currentPage === totalPages
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-blue-500 text-white hover:bg-blue-600'
            }`}
          >
            Siguiente
          </button>
        </div>
      )}
    </div>
  );
};

export default InactiveEvents;

import React, { useEffect, useState, useCallback } from 'react';
import { getPublicVisibilityPercentageMetrics } from '../../../services/api';
import { useAuth0 } from '@auth0/auth0-react';
import './PublicVisibilityPercentageMetric.css';

const PublicVisibilityPercentageMetric = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetchMetrics = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const result = await getPublicVisibilityPercentageMetrics(token);
      setData(result);
    } catch (err) {
      console.error('Error obteniendo el porcentaje de visibilidad pública:', err);
      setError('No se pudo obtener el porcentaje de visibilidad pública.');
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  if (error) return <div className="public-visibility-metric metric-card">{error}</div>;

  return (
    <div className="public-visibility-metric metric-card">
      <h2>Porcentaje de Visibilidad Pública en Asistencia</h2>
      {data ? (
        <div className="metric-result">
          <p><strong>Total de Asistentes:</strong> {data.total_attendees}</p>
          <p><strong>Asistentes con Visibilidad Pública:</strong> {data.public_visibility_attendees}</p>
          <p><strong>Porcentaje de Visibilidad Pública:</strong> {data.percentage_public_visibility}%</p>
        </div>
      ) : (
        <p>Cargando datos...</p>
      )}
    </div>
  );
};

export default PublicVisibilityPercentageMetric;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getUserInfoById, getUserEvents, getUserReports, activateUser, deactivateUser } from '../../services/api';
import { useAuth0 } from "@auth0/auth0-react";
import config from '../../configroutes';
import EventCard from '../../components/EventCard/EventCard.js'
import PopUpReport from '../../components/Report/PopUpReport.js';



const ProfileView = () => {
    const { id } = useParams()
    const { getAccessTokenSilently, user } = useAuth0(); // Para obtener el token de autenticación
    const [isLoading, setIsLoading] = useState(true);
    const [userProfile, setUserProfile] = useState(null);
    const [error, setError] = useState(null);
    const [currentEvents, setCurrentEvents] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);
    const [userEvents, setUserEvents] = useState([]);
    const [userReports, setUserReports] = useState([]);
    const [showReportsPopup, setShowReportsPopup] = useState(false);
    const navigate = useNavigate(); 
    const now = new Date();

    const fetchUserProfile = async () => {
        try {
            setIsLoading(true);
            const token = await getAccessTokenSilently(); // Obtener el token para autenticación
            const userInfo = await getUserInfoById(token, id); // Obtener los datos del usuario
            const eventInfo = await getUserEvents(token, id); // Obtener los eventos del usuario
            const reportInfo = await getUserReports(token, id); // Obtener las denuncias del usuario

            const upcomingEvents = eventInfo.filter(event => new Date(event.start_date) > now);
            const previousEvents = eventInfo.filter(event => new Date(event.start_date) <= now);
            
            setUserProfile(userInfo);
            setUserEvents(eventInfo);
            setCurrentEvents(upcomingEvents);
            setPastEvents(previousEvents);
            setUserReports(reportInfo);

        } catch (error) {
            console.error('Error al cargar el perfil del usuario:', error.message); // Mostrar el error en consola
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    }

    const handleUserStatus = async () => {
        try {
            const token = await getAccessTokenSilently();
            if (userProfile.is_active) {
                const confirmDeactivation = window.confirm("¿Está seguro de que desea desactivar el usuario?");
                if (!confirmDeactivation) return; 
                await deactivateUser(token, id); // Si confirma, desactiva al usuario
            } else {
                // Si el usuario está inactivo, activarlo sin confirmación
                await activateUser(token, id);
            }
    
            await fetchUserProfile(); // Actualiza el perfil del usuario
    
        } catch (error) {
            console.error('Error al cambiar el estado del usuario:', error);
            setError('No se pudo cambiar el estado del usuario');
        }
    };

    const handleGoBack = () => {
        navigate(-1); // Esta función retrocede a la página anterior
    };

    useEffect(() => {
        fetchUserProfile();
    }, [id]);

    // Si los datos aún se están cargando
    if (isLoading) {
        return <div>Cargando perfil...</div>;
    }

    // Si hay un error
    if (error) {
        return <div>Error: {error}</div>;
    }

    // Si el perfil no fue encontrado
    if (!userProfile) {
        return <div>No se encontró el perfil del usuario.</div>;
    }

    const totalPastEvents = pastEvents.length;
    const totalCurrentEvents = currentEvents.length;
    const totalEvents = totalPastEvents + totalCurrentEvents;
    

    return (
        <div className="container mx-auto mt-10 max-w-4xl">
            {/* Muestra si el usuario está activo o desactivado */}
            <button onClick={handleGoBack} className="flex items-center text-gray-500 hover:text-black">
                    <span className="ml-2">Volver</span>
            </button>

            <div className={`text-center py-2 font-semibold text-lg ${userProfile.is_active ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'}`}>
                {userProfile.is_active ? 'USUARIO ACTIVO' : 'USUARIO DESACTIVADO'}
            </div>
            
            {/* Encabezado del perfil */}
            <div className="flex items-center justify-between py-5 border-b">
                <div className="flex items-center space-x-5">
                    <img 
                        src={userProfile.profile_link ? `${config.images_route}/${userProfile.profile_link}` : '/images/imagen_perfil_default.png'} 
                        alt={`Avatar de ${userProfile.name}`} 
                        className="w-16 h-16 rounded-full object-cover" 
                    />
                    <div>
                        <h2 className="text-2xl font-bold">{userProfile.name}</h2>
                        {userProfile.organizer ? (
                            <span className="text-xs font-semibold text-green-500">ORGANIZADOR</span>
                        ) : (
                            <span className="text-xs font-semibold text-blue-500">USUARIO</span>
                        )}
                        <p className="text-sm text-gray-500">{userProfile.description}</p>
                        <div className="flex items-center space-x-2">
                            <a href={userProfile.website || '#'} className="text-blue-500 hover:underline">{userProfile.website}</a>
                        </div>
                        <div className="flex items-center space-x-2">
                            <p className="text-sm text-gray-500">
                                {userProfile.profile_privacy ? 'Privado' : 'Público'}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex space-x-10 text-center">
                    <div className="text-center text-black">
                        <span className="block text-lg font-bold">{totalEvents}</span>
                        <span className="text-sm text-gray-500">Eventos</span>
                    </div>
                    <div className="text-black">
                        <span className="block text-lg font-bold">{userProfile.followers}</span>
                        <span className="text-sm text-gray-500">Seguidores</span>
                    </div>
                    <div className="text-black">
                        <span className="block text-lg font-bold">{userProfile.following}</span>
                        <span className="text-sm text-gray-500">Seguidos</span>
                    </div>
                </div>
            </div>

            {/* Sección de denuncias del usuario */}
            <div className="flex flex-col items-center my-6">
                <div
                    className={`rounded-lg px-6 py-4 shadow-lg max-w-xs text-center cursor-pointer transition duration-200 ${
                    userReports.length === 0 ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'
                    }`}
                    onClick={() => setShowReportsPopup(true)} // Mostrar el popup al hacer clic
                >
                    <h3 className="text-lg font-semibold">Denuncias del Usuario</h3>
                    <p className="text-4xl font-bold my-2">{userReports.length}</p>
                </div>
            </div>
            
            {showReportsPopup && (
                <PopUpReport
                    isOpen={showReportsPopup}
                    onClose={() => setShowReportsPopup(false)}
                    title="Denuncias del Usuario"
                    reports={userReports} // Pasa los reports como prop
                />
            )}

            {/* Botón de activar/desactivar */}
            <div className="flex justify-center mt-5 space-x-4">
                {userProfile.is_active ? (
                    <button
                        className="text-white font-bold py-2 px-4 rounded border hover:bg-red-600 bg-red-500"
                        onClick={() => handleUserStatus()} // Llama a la función de desactivación
                    >
                        Desactivar
                    </button>
                ) : (
                    <button
                        className="text-white font-bold py-2 px-4 rounded border hover:bg-green-600 bg-green-500"
                        onClick={() => handleUserStatus()} // Llama a la función de activación
                    >
                        Activar
                    </button>
                )}
            </div>

            {/* Sección de eventos actuales */}
            <div className="mt-8">
                <h3 className="text-xl font-semibold mb-4">Eventos Actuales ({totalCurrentEvents})</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {currentEvents.length > 0 ? (
                        currentEvents.map((event) => (
                            
                            <EventCard
                                key={event.id_event}
                                id_event={event.id_event}
                                link_photo={event.link_photo}
                                title={event.title}
                                organizer={event.organizer}
                                start_date={event.start_date}
                                end_date={event.end_date}
                                location={event.location}
                                active = {event.is_active}
                            />
                        ))
                    ) : (
                        <p className="text-gray-500">No hay eventos actuales para mostrar.</p>
                    )}
                </div>
            </div>

            {/* Sección de eventos pasados */}
            <div className="mt-8">
                <h3 className="text-xl font-semibold mb-4">Eventos Pasados ({totalPastEvents})</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {pastEvents.length > 0 ? (
                        pastEvents.map((event) => (
                            <EventCard
                                key={event.id_event}
                                id_event={event.id_event}
                                link_photo={event.link_photo}
                                title={event.title}
                                organizer={event.organizer}
                                start_date={event.start_date}
                                end_date={event.end_date}
                                location={event.location}
                                active = {event.is_active}
                            />
                        ))
                    ) : (
                        <p className="text-gray-500">No hay eventos pasados para mostrar.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProfileView;
import React, { useEffect, useState, useCallback } from 'react';
import { getTopEventTimesAndPlacesMetrics } from '../../../services/api';
import { useAuth0 } from '@auth0/auth0-react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import './TopEventTimesAndPlacesMetric.css';

const TopEventTimesAndPlacesMetric = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [limit, setLimit] = useState(5);

  const fetchMetrics = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const result = await getTopEventTimesAndPlacesMetrics(token, limit);
      setData(result);
    } catch (err) {
      console.error('Error obteniendo métricas de tiempos y lugares de eventos populares:', err);
      setError('No se pudieron obtener las métricas de tiempos y lugares de eventos populares.');
    }
  }, [getAccessTokenSilently, limit]);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setData(null);
    setError(null);
    fetchMetrics();
  };

  const openMap = (location) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`;
    window.open(url, '_blank');
  };

  const formatDayOfWeek = (dayOfWeek) => {
    const days = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    return days[dayOfWeek] || dayOfWeek;
  };

  if (error) return <div className="top-events-metric metric-card">{error}</div>;

  return (
    <div className="top-events-metric metric-card">
      <h2>Horarios y Lugares Populares para Eventos</h2>
      <form onSubmit={handleSubmit} className="metric-form">
        <label>
          Límite:
          <input
            type="number"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
            min="1"
            className="input-field"
          />
        </label>
        <button type="submit" className="btn-submit">Actualizar</button>
      </form>
      {data ? (
        <div className="metric-result">
          <div className="popular-summary">
            <h4>Resumen:</h4>
            <p><strong>Ubicación:</strong> {data.top_event_times_and_places?.most_popular_location?.location} ({data.top_event_times_and_places?.most_popular_location?.total_events} eventos)</p>
            <p><strong>Día:</strong> {formatDayOfWeek(data.top_event_times_and_places?.most_popular_day?.event_day_of_week)} ({data.top_event_times_and_places?.most_popular_day?.total_events} eventos)</p>
            <p><strong>Hora:</strong> {data.top_event_times_and_places?.most_popular_time?.event_time} ({data.top_event_times_and_places?.most_popular_time?.total_events} eventos)</p>
          </div>
          <h3>Top Horarios y Lugares:</h3>
          <ul className="events-list">
            {data.top_event_times_and_places?.top_event_times_places_days.map((event, index) => (
              <li key={index} className="event-item">
                <span
                  className="event-location clickable"
                  onClick={() => openMap(event.location)}
                >
                  <FaMapMarkerAlt className="location-icon" /> {event.location}
                </span>
                <span className="event-day">Día: {formatDayOfWeek(event.event_day_of_week)}</span>
                <span className="event-time">Hora: {event.event_time}</span>
                <span className="event-total">Total: {event.total_events} eventos</span>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>Cargando datos...</p>
      )}
    </div>
  );
};

export default TopEventTimesAndPlacesMetric;

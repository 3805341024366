import React, { useEffect, useState } from 'react';
import { getNewAccountsMetrics } from '../../../services/api'; 
import { useAuth0 } from '@auth0/auth0-react';
import './NewAccountsMetric.css';
import { FaUserAlt, FaBuilding } from 'react-icons/fa';

const NewAccountsMetric = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const token = await getAccessTokenSilently();
        const result = await getNewAccountsMetrics(token);
        setData(result);
      } catch (err) {
        console.error('Error al obtener métricas de nuevas cuentas:', err);
        setError('No se pudieron obtener las métricas de nuevas cuentas.');
      }
    };

    fetchMetrics();
  }, [getAccessTokenSilently]);

  if (error) return <div className="new-accounts-metric metric-card">{error}</div>;

  return (
    <div className="new-accounts-metric metric-card">
      <h2>Nuevas Cuentas</h2>
      {data ? (
        <div className="metric-content">
          <div className="metric-section">
            <h3>Última semana</h3>
            <p><FaUserAlt className="icon" /> Usuarios: {data.last_week.new_users}</p>
            <p><FaBuilding className="icon" /> Organizadores: {data.last_week.new_organizations}</p>
          </div>
          <div className="metric-section">
            <h3>Último mes</h3>
            <p><FaUserAlt className="icon" /> Usuarios: {data.last_month.new_users}</p>
            <p><FaBuilding className="icon" /> Organizadores: {data.last_month.new_organizations}</p>
          </div>
          <div className="metric-section">
            <h3>Último año</h3>
            <p><FaUserAlt className="icon" /> Usuarios: {data.last_year.new_users}</p>
            <p><FaBuilding className="icon" /> Organizadores: {data.last_year.new_organizations}</p>
          </div>
        </div>
      ) : (
        <p className="loading-text">Cargando datos...</p>
      )}
    </div>
  );
};

export default NewAccountsMetric;

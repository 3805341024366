import React from 'react';
import { useNavigate } from 'react-router-dom';
import UsersWithReportsMetric from './Reports_Components/UsersWithReportsMetric';
import EventsWithReportsMetric from './Reports_Components/EventsWithReportsMetric';
import ReviewsWithReportsMetric from './Reports_Components/ReviewsWithReportsMetric';
import QuestionsWithReportsMetric from './Reports_Components/QuestionsWithReportsMetric';
import './ControlPanel.css';

const ControlPanel = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="control-panel">
      <header className="control-panel-header">
        <button onClick={handleGoBack} className="back-button">
          <span>Volver</span>
        </button>
      </header>
      <div className="control-panel-content">
        <section className="controlmetric-section">
          <h2 className="section-title">Métricas de Reportes</h2>
          <div className="controlmetric-cards">
            <div className="controlmetric-row">
              <UsersWithReportsMetric />
            </div>
            <div className="controlmetric-row">
              <EventsWithReportsMetric />
            </div>
            <div className="controlmetric-row">
              <ReviewsWithReportsMetric />
            </div>
            <div className="controlmetric-row">
              <QuestionsWithReportsMetric />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ControlPanel;

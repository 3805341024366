import axios from 'axios';
import config from '../configroutes.js';


//
//Healthcheck ADMINISTRADOR
//
export const checkAdminStatus = async (token) => {
    try {
      const response = await axios.get(`${config.route}/api/admin/healthcheck`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.status === 200;
    } catch (error) {
      console.error('Error al verificar permisos de administrador:', error);
      throw error;
    }
  };

  
//
// Métricas ADMINISTRADOR
//

// Obtener la cantidad de cuentas nuevas creadas
export const getNewAccountsMetrics = async (token) => {
  try {
    const response = await axios.get(`${config.route}/api/admin/metrics/new_accounts`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error obteniendo métricas de cuentas nuevas:', error);
    throw error;
  }
};

// Obtener el número de usuarios que han asistido a un mínimo de eventos en los últimos meses
export const getUsersAttendanceMetrics = async (token, months = 3, min_events = 3) => {
  try {
    const response = await axios.get(`${config.route}/api/admin/metrics/users_attendance`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { months, min_events },
    });
    return response.data;
  } catch (error) {
    console.error('Error obteniendo métricas de asistencia de usuarios:', error);
    throw error;
  }
};

// Obtener los usuarios con más seguidores
export const getUsersMostFollowersMetrics = async (token, limit = 5) => {
  try {
    const response = await axios.get(`${config.route}/api/admin/metrics/users_most_followers`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { limit },
    });
    return response.data;
  } catch (error) {
    console.error('Error obteniendo métricas de usuarios con más seguidores:', error);
    throw error;
  }
};

// Obtener las categorías con más eventos
export const getTopCategoriesMetrics = async (token, limit = 3) => {
  try {
    const response = await axios.get(`${config.route}/api/admin/metrics/top_categories`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { limit },
    });
    return response.data;
  } catch (error) {
    console.error('Error obteniendo métricas de categorías principales:', error);
    throw error;
  }
};

// Obtener los tiempos y lugares más populares para eventos
export const getTopEventTimesAndPlacesMetrics = async (token, limit = 5) => {
  try {
    const response = await axios.get(`${config.route}/api/admin/metrics/top_event_times_and_places`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { limit },
    });
    return response.data;
  } catch (error) {
    console.error('Error obteniendo métricas de tiempos y lugares de eventos populares:', error);
    throw error;
  }
};

// Obtener los usuarios con más eventos
export const getUsersMostEventsMetrics = async (token, limit = 3) => {
  try {
    const response = await axios.get(`${config.route}/api/admin/metrics/users_most_events`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { limit },
    });
    return response.data;
  } catch (error) {
    console.error('Error obteniendo métricas de usuarios con más eventos:', error);
    throw error;
  }
};

// Obtener el porcentaje de visibilidad pública de asistencia a eventos
export const getPublicVisibilityPercentageMetrics = async (token) => {
  try {
    const response = await axios.get(`${config.route}/api/admin/metrics/public_visibility_percentage`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error obteniendo métrica de porcentaje de visibilidad pública:', error);
    throw error;
  }
};

// Obtener las categorías más populares por asistentes
export const getTopCategoriesByAttendeesMetrics = async (token, limit = 5) => {
  try {
    const response = await axios.get(`${config.route}/api/admin/metrics/top_categories_by_attendees`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { limit },
    });
    return response.data;
  } catch (error) {
    console.error('Error obteniendo métricas de categorías populares por asistentes:', error);
    throw error;
  }
};

// Obtener la cantidad de usuarios que llegaron por recomendación
export const getArrivalsThroughOtherPersonMetrics = async (token) => {
  try {
    const response = await axios.get(`${config.route}/api/admin/metrics/arrivals_through_other_person`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error obteniendo métricas de llegadas por recomendación:', error);
    throw error;
  }
};

//
// Búsqueda de eventos y usuarios ADMINISTRADOR
//

// Búsqueda de eventos para el administrador
export const adminSearchEvents = async (token, searchText = "") => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`
      }
  };
  const url = `${config.route}/api/admin/event/search/?description=${searchText}`;

  try {
      const response = await axios.get(url, configaxios);
      return response.data.events; // Supone que la respuesta contiene un array de eventos
  } catch (error) {
      console.error("Error en adminSearchEvents:", error); // Puedes registrar el error para depuración
      return null; // Devuelve null o un valor vacío si ocurre un error
  }
};

// Búsqueda de usuarios normal
export const searchUsers = async (token, searchText, page = 1) => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`
      }
  };
  const url = `${config.route}/api/user/search/?name=${searchText}&page=${page}`;
  const response = await axios.get(url, configaxios);
  return response.data.users; 
};

// Búsqueda de usuarios para el administrador
export const adminSearchUsers = async (token, searchText = "") => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`
      }
  };
  const url = `${config.route}/api/admin/users/search/?name=${searchText}`;

  try {
      const response = await axios.get(url, configaxios);
      return response.data.users;
  } catch (error) {
      console.error("Error en adminSearchUsers:", error); 
      return null; // Devuelve null o un valor vacío si ocurre un error
  }
};


// Usuario
// GET /api/admin/user/{user_id}
export const getUserInfoById = async (token, userId) => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`, 
      }
  };
  // Endpoint con el ID del usuario
  const url = `${config.route}/api/admin/user/${userId}`;

  try {
      const response = await axios.get(url, configaxios);
      return response.data; // Devolvemos la respuesta completa para verificar que los datos lleguen correctamente
  } catch (error) {
      if (error.response && error.response.status === 404) {
          throw new Error('Usuario no encontrado');
      } else {
          throw new Error('Error al obtener información del usuario');
      }
  }
};

// GET /api/admin/user/{user_id}/events/?page={page}&page_size={page_size}
export const getUserEvents = async (token, userId, page = 1, pageSize = 100) => {
  const configaxios = {
    headers: {
        "Authorization": `Bearer ${token}`, 
    }
  };
  const url = `${config.route}/api/admin/user/${userId}/events/?page=${page}&page_size=${pageSize}`;
  try {
    const response = await axios.get(url, configaxios);
    return response.data.events;
  } catch (error) {
    console.error("Error en userEvents:", error);
    return null;
  }
}

// GET /api/admin/event/{event_id}
export const getEventInfoById = async (token, eventId) => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`, 
      }
  };
  // Endpoint con el ID del evento
  const url = `${config.route}/api/admin/event/${eventId}`;

  try {
      const response = await axios.get(url, configaxios);
      return response.data; // Devolvemos la respuesta completa para verificar que los datos lleguen correctamente
  }
  catch (error) {
      if (error.response && error.response.status === 404) {
          throw new Error('Evento no encontrado');
      } else {
          throw new Error('Error al obtener información del evento');
      }
  }
}

// GET /api/admin/event/{event_id}/attendees?page={page}&page_size={page_size}
export const getEventAttendees = async (token, eventId, page = 1, pageSize = 10) => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`, 
      }
  };
  const url = `${config.route}/api/admin/event/${eventId}/attendees?page=${page}&page_size=${pageSize}`;

  try {
    const response = await axios.get(url, configaxios);
    return response.data;
  } catch (error) {
    console.error("Error en userEvents:", error);
    return null;
  }
}

// GET /api/admin/event/{event_id}/reviews?page={page}&page_size={page_size}
export const getEventReview = async (token, eventId, page = 1, pageSize = 10) => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`, 
      }
  };
  const url = `${config.route}/api/admin/event/${eventId}/reviews?page=${page}&page_size=${pageSize}`;
  try {
    const response = await axios.get(url, configaxios);
    return response.data;
  } catch (error) {
    console.error("Error en userEvents:", error);
    return null;
  }
}

// GET /api/admin/event/{event_id}/posts?page={page}&page_size={page_size}
export const getEventPosts = async (token, eventId, page = 1, pageSize = 10) => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`, 
      }
  };
  const url = `${config.route}/api/admin/event/${eventId}/posts?page=${page}&page_size=${pageSize}`;
  try {
    const response = await axios.get(url, configaxios);
    return response.data;
  } catch (error) {
    console.error("Error en userEvents:", error);
    return null;
  }
}

// PUT /api/admin/deactivate_event/{event_id}
export const deactivateEvent = async (token, eventId) => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`, 
      }
  };
  
  
  const url = `${config.route}/api/admin/deactivate_event/${eventId}`;
  try {
    const response = await axios.put(url, {}, configaxios);
    return response.data;
  } catch (error) {
    console.error("Error en deactivateEvent:", error);
    return null;
  }
}

// PUT /api/admin/activate_review/{event_id}
export const activateEvent = async (token, eventId) => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`, 
      }
  };
  const url = `${config.route}/api/admin/activate_event/${eventId}`;
  try {
    const response = await axios.put(url, {}, configaxios);
    return response.data;
  } catch (error) {
    console.error("Error en activateEvent:", error);
    return null;
  }
}

// PUT /api/admin/deactivate_user/{user_id}
export const deactivateUser = async (token, userId) => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`, 
      }
  };
  const url = `${config.route}/api/admin/deactivate_user/${userId}`;
  try {
    const response = await axios.put(url, {}, configaxios);
    return response.data;
  } catch (error) {
    console.error("Error en deactivateUser:", error);
    return null;
  }
}

// PUT /api/admin/activate_user/{user_id}
export const activateUser = async (token, userId) => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`, 
      } 
  };
  const url = `${config.route}/api/admin/activate_user/${userId}`;
  try {
    const response = await axios.put(url, {}, configaxios);
    return response.data;
  } catch (error) {
    console.error("Error en activateUser:", error);
    return null;
  }
}

// PUT /api/admin/deactivate_review/{review_id}
export const deactivateReview = async (token, reviewId) => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`, 
      }
  };
  const url = `${config.route}/api/admin/deactivate_review/${reviewId}`;
  try {
    const response = await axios.put(url, {}, configaxios);
    return response.data;
  } catch (error) {
    console.error("Error en deactivateReview:", error);
    return null;
  }
}

// PUT /api/admin/activate_review/{review_id}
export const activateReview = async (token, reviewId) => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`, 
      }
  };
  const url = `${config.route}/api/admin/activate_review/${reviewId}`;
  try {
    const response = await axios.put(url, {}, configaxios);
    return response.data;
  } catch (error) {
    console.error("Error en activateReview:", error);
    return null;
  }
}

// GET /api/reported_user/user/{id_user_reported}?page={page}&page_size={page_size}
export const getUserReports = async (token, userId, page = 1, pageSize = 10) => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`, 
      }
  };
  const url = `${config.route}/api/reported_user/user/${userId}?page=${page}&page_size=${pageSize}`;
  try {
    const response = await axios.get(url, configaxios);
    return response.data;
  } catch (error) {
    console.error("Error en userReported:", error);
    return null;
  }
}

// GET /api/reported_event/event/{id_event}?page={page}&page_size={page_size}
export const getEventReports = async (token, eventId, page = 1, pageSize = 10) => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`, 
      }
  };
  const url = `${config.route}/api/reported_event/event/${eventId}?page=${page}&page_size=${pageSize}`;
  try {
    const response = await axios.get(url, configaxios);
    return response.data;
  } catch (error) {
    console.error("Error en eventReports:", error);
    return null;
  }
}

// GET /api/reported_review/review/{id_review}?page={page}&page_size={page_size}
export const getReviewReports = async (token, reviewId, page = 1, pageSize = 10) => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`, 
      }
  };
  const url = `${config.route}/api/reported_review/review/${reviewId}?page=${page}&page_size=${pageSize}`;
  try {
    const response = await axios.get(url, configaxios);
    return response.data;
  } catch (error) {
    console.error("Error en reviewReports:", error);
    return null;
  }
}

export const getQuestionReports = async (token, questionId, page = 1, pageSize = 10) => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`, 
      }
  };
  const url = `${config.route}/api/reported_question/question/${questionId}?page=${page}&page_size=${pageSize}`;
  try {
    const response = await axios.get(url, configaxios);
    return response.data;
  } catch (error) {
    console.error("Error en reviewReports:", error);
    return null;
  }
}

// PREGUNTAS

// GET /api/questions/event/{id_event}
export const getEventQuestions = async (token, eventId) => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`
      }
  };
  const url = `${config.route}/api/admin/questions/${eventId}`;
  const response = await axios.get(url, configaxios);
  return response.data;
}

// PUT /api/admin/deactivate_question/{id_question}
export const deactivateQuestion = async (token, questionId) => {
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`, 
      }
  };
  const url = `${config.route}/api/admin/deactivate_question/${questionId}`;
  try {
    const response = await axios.put(url, {}, configaxios);
    return response.data;
  } catch (error) {
    console.error("Error en deactivateQuestion:", error);
    return null;
  }
}


// PUT /api/admin/activate_question/{id_question}
export const activateQuestion= async (token, questionId) => {
  console.log(token);
  const configaxios = {
      headers: {
          "Authorization": `Bearer ${token}`, 
      }
  };
  const url = `${config.route}/api/admin/activate_question/${questionId}`;
  console.log("activar")
  try {
    const response = await axios.put(url, {}, configaxios);
    return response.data;
  } catch (error) {
    console.error("Error en activateQuestion:", error);
    return null;
  }
}
///////////////////////////////
// Panel de Control
///////////////////////////////

// GET /api/admin/metrics/users_with_reports_above_limit/?limit={limit}
export const getUsersWithReportsAboveLimit = async (token, limit = 3) => {
  const configaxios = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const url = `${config.route}/api/admin/metrics/users_with_reports_above_limit?limit=${limit}`;
  try {
    const response = await axios.get(url, configaxios);
    return response.data;
  } catch (error) {
    console.error("Error en getUsersWithReportsAboveLimit:", error);
    return null;
  }
};

// GET /api/admin/metrics/events_with_reports_above_limit/?limit={limit}
export const getEventsWithReportsAboveLimit = async (token, limit = 3) => {
  const configaxios = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const url = `${config.route}/api/admin/metrics/events_with_reports_above_limit?limit=${limit}`;
  try {
    const response = await axios.get(url, configaxios);
    return response.data;
  } catch (error) {
    console.error('Error en getEventsWithReportsAboveLimit:', error);
    return null;
  }
};

// GET /api/admin/metrics/reviews_with_reports_above_limit/?limit={limit}
export const getReviewsWithReportsAboveLimit = async (token, limit = 3) => {
  const configaxios = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const url = `${config.route}/api/admin/metrics/reviews_with_reports_above_limit?limit=${limit}`;
  try {
    const response = await axios.get(url, configaxios);
    return response.data;
  } catch (error) {
    console.error('Error en getReviewsWithReportsAboveLimit:', error);
    return null;
  }
};

// GET /api/admin/metrics/questions_with_reports_above_limit/?limit={limit}
export const getQuestionsWithReportsAboveLimit = async (token, limit = 3) => {
  const configaxios = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const url = `${config.route}/api/admin/metrics/questions_with_reports_above_limit?limit=${limit}`;
  try {
    const response = await axios.get(url, configaxios);
    return response.data;
  } catch (error) {
    console.error('Error en getQuestionsWithReportsAboveLimit:', error);
    return null;
  }
};


///////////////////////
// Inactivos
///////////////////////
export const getInactiveUsers = async (token, page = 1, pageSize = 10) => {
  const response = await fetch(
    `${config.route}/api/admin/users/deactivated?page=${page}&page_size=${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.detail || 'Error al obtener usuarios desactivados');
  }

  return await response.json();
};

export const getInactiveEvents = async (token, page = 1, pageSize = 10) => {
  const response = await fetch(
    `${config.route}/api/admin/events/deactivated?page=${page}&page_size=${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.detail || 'Error al obtener eventos desactivados');
  }

  return await response.json();
};

export const getInactiveReviews = async (token, page = 1, pageSize = 10) => {
  const response = await fetch(
    `${config.route}/api/admin/reviews/deactivated?page=${page}&page_size=${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.detail || 'Error al obtener reseñas desactivadas');
  }

  return await response.json();
};

export const getInactiveQuestions = async (token, page = 1, pageSize = 10) => {
  const response = await fetch(
    `${config.route}/api/admin/questions/deactivated?page=${page}&page_size=${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.detail || 'Error al obtener preguntas desactivadas');
  }

  return await response.json();
};